<template>
    <v-container fill-height fluid text-xs-center>
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-data-table
                :headers="headers"
                :items="events"
                :items-per-page="10"
                :search="searchEvent"
                :loading="loadingData"
                :expanded="expanded"
                single-expand
                show-expand
                item-key="id"
                sort-by="id"
                disable-sort
                no-data-text="No hay datos para mostrar..."
                loading-text="Cargando los datos, por favor espere."
                class="elevation-1"
                :footer-props="{
                        showFirstLastPage: true,
                        disableItemsPerPage: false,
                        itemsPerPageAllText: 'Todas',
                        itemsPerPageText: 'Filas por página:'
                    }">
                <template v-slot:top>
                    <v-toolbar flat class="align-center">
                        <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'text-subtitle-1 text-md-h5' : ''">
                            <span>Asignaciones de Eventos</span>
                        </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
                            <v-text-field
                                id="findtext-project"
                                label="Buscar..."
                                name="findproject"
                                outlined
                                dense
                                single-line
                                clearable
                                background-color="white"
                                hide-details
                                v-model="searchEvent"
                                class="white--text mt-0 pt-0 mr-1"
                                prepend-inner-icon="mdi-magnify"
                            ></v-text-field>
                        </v-responsive>
                        <v-btn @click="getEvents"
                               :loading="loadingData"
                               elevation="0"
                               :small="$vuetify.breakpoint.smAndDown"
                               class="mb-1 ml-2"
                        >
                          <v-icon>mdi-reload</v-icon>
                        </v-btn>
                        <v-dialog
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                            v-model="dialogDocumentsPDF"
                            persistent
                            width="600">
                            <v-card>
                                <v-toolbar dark color="primary" height="50">
                                    <v-btn
                                        class="hidden-sm-and-down"
                                        icon
                                        dark
                                        @click="dialogDocumentsPDF = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Documentos PDF</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn dark
                                               text
                                               @click="dialogDocumentsPDF = false">
                                            Cerrar
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container fill-height bottom-gradient fluid class="ma-0">
                                    <v-row>
                                        <v-col>
                                            <div class="fill-height">
                                                <v-list dense subheader>
                                                    <v-subheader>Lista de Archivos</v-subheader>
                                                    <v-divider/>
                                                    <v-list-item
                                                        v-for="(doc, idx) in event_documents"
                                                        :key="idx">
                                                        <v-list-item-icon>
                                                            <v-icon color="primary">
                                                                mdi-file-document
                                                            </v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title
                                                                v-text="doc.event_document_name"></v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-icon>
                                                            <v-tooltip bottom v-if="doc.event_document_url != null">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                        dark
                                                                        icon
                                                                        small
                                                                        color="orange"
                                                                        class="ma-0 text--darken-1"
                                                                        elevation="0"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        v-on:click="openPDF(doc)">
                                                                        <v-icon>mdi-magnify</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Ver documento</span>
                                                            </v-tooltip>
                                                        </v-list-item-icon>
                                                    </v-list-item>
                                                </v-list>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                            v-model="dialogChartEvent"
                            persistent
                            width="700">
                            <v-card>
                                <v-toolbar dark color="primary" height="60">
                                    <v-btn
                                        class="hidden-sm-and-down"
                                        icon
                                        dark
                                        @click="dialogChartEvent = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Gr&aacute;fica del Evento</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn dark
                                               text
                                               @click="dialogChartEvent = false">
                                            Cerrar
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container fill-height bottom-gradient fluid class="ma-0">
                                    <v-row>
                                        <v-col>
                                            <div class="fill-height">
                                                <div class="container--fluid fill-height" style="width:100%;">
                                                    <VueApexCharts
                                                        ref="chartEventsInvestor"
                                                        height="400" type="line" width="100%"
                                                        :options="chartEventOptions"
                                                        :series="seriesEvent"/>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogProject"
                                  transition="dialog-bottom-transition"
                                  hide-overlay
                                  max-width="550">
                            <v-card>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-img
                                                :src="projectData.project.pictures.length > 0 ?
                                                       projectData.project.pictures[0].url :
                                                       require('@/assets/images/no-image.jpg')">
                                            </v-img>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ projectData.project.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>Proyecto</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <v-divider></v-divider>
                                <v-card-text class="mt-3">
                                    <table cellspacing="3" cellpadding="3" class="text-left">
                                        <tr style="vertical-align: top">
                                            <th>Descripción:</th>
                                            <td>{{ projectData.project.description }}</td>
                                        </tr>
                                        <tr style="vertical-align: top">
                                            <th>Ubicación:</th>
                                            <td>{{ projectData.project.location }}</td>
                                        </tr>
                                        <tr>
                                            <th>Monto de Inversión:</th>
                                            <td>{{ projectData.investment_amount | toCurrency }} mxn</td>
                                        </tr>
                                        <tr>
                                            <th>Ingresos:</th>
                                            <td>{{ projectData.investment_income | toCurrency }} mxn</td>
                                        </tr>
                                        <tr>
                                            <th>Gastos:</th>
                                            <td>{{ projectData.expense | toCurrency }} mxn</td>
                                        </tr>
                                        <tr>
                                            <th>Utilidad:</th>
                                            <td>{{ projectData.utility | toCurrency }} mxn</td>
                                        </tr>
                                    </table>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="dialogProject = false"
                                    >Cerrar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.active="{ item }">
                    <v-simple-checkbox
                        v-model="item.active"
                        disabled
                    ></v-simple-checkbox>
                </template>
                <template v-slot:item.event_image_url="{ item }">
                    <v-avatar color="#ded06f" size="40">
                        <v-img
                            :src="(item.event_image_url !== null || item.event_image_url !== '') ? item.event_image_url :
                            require('@/assets/images/no-image.jpg')">
                        </v-img>
                    </v-avatar>
                </template>
                <template v-slot:item.event_start_date="{ item }">
                    <span>{{ formatDate(item.event_start_date) }}</span>
                </template>
                <template #item.event_amount="{ item }">
                    <span class="text-caption">
                        {{ item.event_amount | toCurrency }} mxn
                    </span>
                </template>
                <template #item.event_income="{ item }">
                    <span class="text-caption">
                        {{ item.event_income | toCurrency }} mxn
                    </span>
                </template>
                <template #item.event_expense="{ item }">
                    <span class="text-caption">
                        {{ item.event_expense | toCurrency }} mxn
                    </span>
                </template>
                <template #item.event_utility="{ item }">
                    <span class="text-caption">
                        {{ item.event_utility | toCurrency }} mxn
                    </span>
                </template>
                mdi-clipboard-check
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top v-if="!item.event_stand_alone">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="viewProject(item)">
                                mdi-clipboard-check
                            </v-icon>
                        </template>
                        <span>Asociado a un Proyecto</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="loadListDocuments(item)">
                                mdi-file-document
                            </v-icon>
                        </template>
                        <span>Mostrar Documentos PDF</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                color="black"
                                small
                                class="mr-2"
                                @click="showChartEvent(item)">
                                mdi-chart-areaspline
                            </v-icon>
                        </template>
                        <span>Gr&aacute;fica del Evento</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <span class="text-h5">No hay registros en el listado</span>
                    <br/>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <v-container fluid>
                            <v-row justify="start" class="ma-1">
                                <h3>{{ item.event_name }}</h3>
                            </v-row>
                            <v-row class="ma-1">
                                <v-col cols="12" md="8">
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Descripci&oacute;n:
                                        </v-col>
                                        <v-col>
                                            {{ item.event_description }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Ubicaci&oacute;n:
                                        </v-col>
                                        <v-col>
                                            {{ item.event_location }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Fecha de inicio:
                                        </v-col>
                                        <v-col>
                                            {{ formatDate(item.event_start_date) }}
                                        </v-col>
                                    </v-row>
                                    <!--                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="false">
                                                                            <v-col cols="12" md="4">
                                                                                Fecha estimada de terminación:
                                                                            </v-col>
                                                                            <v-col>
                                                                                {{ formatDate(item.finish_date) }}
                                                                            </v-col>
                                                                        </v-row>-->
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Monto Inversi&oacute;n:
                                        </v-col>
                                        <v-col>
                                            {{ item.event_amount | toCurrency }} mxn
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Ingresos:
                                        </v-col>
                                        <v-col>
                                            {{ item.event_income | toCurrency }} mxn
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="true">
                                        <v-col cols="12" md="4">
                                            Gasto de Inversi&oacute;n:
                                        </v-col>
                                        <v-col>
                                            {{ item.event_expense | toCurrency }} mxn
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="true">
                                        <v-col cols="12" md="4">
                                            Utilidad:
                                        </v-col>
                                        <v-col>
                                            {{ item.event_utility | toCurrency}} mxn
                                        </v-col>
                                    </v-row>
                                    <!--                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="false">
                                                                            <v-col cols="12" md="4">
                                                                                Canal de Youtube:
                                                                            </v-col>
                                                                            <v-col>
                                                                                <span v-if="item.youtube_video !== null">
                                                                                    {{ item.youtube_video }}
                                                                                </span>
                                                                                <span v-else>
                                                                                    (ninguno)
                                                                                </span>
                                                                            </v-col>
                                                                        </v-row>-->
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="false">
                                        <v-col cols="12" md="4">
                                            Estado del Evento:
                                        </v-col>
                                        <v-col>
                                            <span v-if="item.event_status === 'started'">
                                                Iniciado
                                            </span>
                                            <span v-else-if="item.event_status === 'execution'">
                                                En avance
                                            </span>
                                            <span v-else>
                                                Terminado
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-row justify="center">
                                        <v-img
                                            :src="item.event_image_url != null ? item.event_image_url :
                                            require('@/assets/images/no-image.jpg')"
                                            :lazy-src="require('@/assets/images/no-image.jpg')"
                                            height="100%"
                                            width="100%"
                                            aspect-ratio="1"
                                            class="white--text align-center justify-center elevation-2">
                                        </v-img>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </td>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
    import investorService from '@/providers/InvestorService';
    import VueApexCharts from "vue-apexcharts";
    // import EventChartSettings from "./EventChartSettings";

    function formatToUnits(number, precision) {
        const abbrev = ['', ' K', ' MDP', ' B', ' T'];
        const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3)
        const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length -1 ))
        const suffix = abbrev[order];

        return (number / Math.pow(10, order * 3)).toFixed(precision) + suffix;
    }

    export default {
        name: "InvestorEventsComponent",
        components: { VueApexCharts },
        data: () => ({
            dialogProject: false,
            dialogDocumentsPDF: false,
            dialogChartEvent: false,
            event_documents: [],
            searchEvent: "",
            loadingData: false,
            investor: null,
            event: {},
            events: [],
            projectData: {
                id: 0,
                project_id: 0,
                investor_id: 0,
                investment_amount: 0,
                investment_points: 0,
                investment_income: 0,
                cost: 0,
                expense: 0,
                revenue: 0,
                utility: 0,
                capital_grow: 0,
                perc_utility: 0,
                project: {
                    id: 0,
                    name: "",
                    location: "",
                    investment_amount: 0,
                    project_participation: 0,
                    utility: 0,
                    return_percentage: 0,
                    active: true,
                    status: "started",
                    description: "Proyecto Condominio de Edificios Residenciales",
                    investment_income: 0,
                    investment: 0,
                    archived: false,
                    cost: 0,
                    expense: 0,
                    revenue: 0,
                    pictures: []
                }
            },
            expanded: [],
            selectedInvestorId: null,
            // eventChartSettings: EventChartSettings,
            headers: [
                {text: '', value: 'data-table-expand', width: 5},
                {text: "Id", value: "event_id", align: " d-none"},
                {text: '', value: 'event_image_url', width: 40},
                {
                    text: "Nombre del Evento",
                    align: "start",
                    sortable: false,
                    value: "event_name",
                    width: 150
                },
                {text: "Proyecto", align: "start", sortable: false, value: "event_project", width: 240},
                {text: "Fecha de Inicio", value: "event_start_date", width: 115},
                {text: "Monto de Inversión", value: "event_amount", width: 145, align: "right"},
                {text: "Ingresos Estimados", value: "event_income", width: 145, align: "right"},
                {text: "Gastos Planificados", value: "event_expense", width: 145, align: "right"},
                {text: "Utilidad Esperada", value: "event_utility", width: 145, align: "right"},
                {text: "Acciones", value: "actions", width: 105, align: "right", sortable: false}
            ],
            seriesEvent: [
            {
                name: 'Crecimiento',
                type: 'column',
                data: []
            },{
                name: 'Ingresos',
                type: 'line',
                data: []
            },{
                name: 'Utilidad',
                type: 'line',
                data: []
            }
            ],
            chartEventOptions: {
                chart: {
                    id: 'chart-events',
                    height: 400,
                    width: '100%',
                    type: 'line',
                    stacked: false,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    }
                },
                stroke: {
                    width: [1, 4, 4]
                },
                markers: {
                    size: 5,
                    strokeColors: "transparent",
                },
                grid: {
                    borderColor: "rgba(0, 0, 0, .1)",
                    show: true,
                    enabled: true,
                    xaxis: {
                        lines: {
                            show: false,
                        },
                    },
                    yaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                colors: [
                    '#4989f6',
                    //'#8000FF',
                    '#079a67',
                    '#88c75e'
                ],
                title: {
                    text: '',
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                tooltip: {
                    enabled: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        console.log('series:', series);
                        console.log('seriesIndex:', seriesIndex);
                        let data = w.globals.initialSeries[0].data[dataPointIndex];
                        return '<v-card elevation="2" outlined class="ma-3 caption transparent text-left justify-start">' +
                            '<table cellpadding="3" cellspacing="3" class="transparent">' +
                                '<tr class="text-left">' +
                                    '<th>Evento: </th>' +
                                    '<th>' +
                                        '<v-card-title class="grey--text text--darken-3">'+data.full_label+'</v-card-title>' +
                                    '</th>' +
                                '</tr>' +
                                '<tr class="card-header" style="color: #4989f6;">' +
                                    '<th>Crecimiento: </th>' +
                                    '<td>' +
                                        '<v-card-title class="grey--text text--darken-3">'+
                                            '<span style="color: #4989f6;">' +
                                                '$ '+formatToUnits(data.y, 2)+
                                            '</span>'+
                                          '</v-card-title>' +
                                    '</td>' +
                                '</tr>' +
                                '<tr class="card-header" style="color: #8000FF;">' +
                                    '<th>Ingresos: </th>' +
                                    '<td>' +
                                        '<v-card-title class="grey--text text--darken-3">'+
                                        '    <span style="color: #8000FF;">'+
                                            '$ '+formatToUnits(data.income, 2)+
                                        '   </span> '+
                                        ' </v-card-title>' +
                                    '</td>' +
                                '</tr>' +
                                '<tr class="card-header" style="color: #079a67;">' +
                                    '<th>Utilidad: </th>' +
                                    '<td>' +
                                        '<v-card-title class="grey--text text--darken-3">'+
                                            '<span style="color: #079a67;">' +
                                                '$ '+formatToUnits(data.utility, 2)+
                                            '</span>'+
                                        '</v-card-title> ' +
                                    '</td>' +
                                '</tr>' +
                            '</table>' +
                        '</v-card>';
                    }
                },
                dataLabels: {
                    enabled: false,
                    enabledOnSeries: [1],
                    formatter: function (value) {
                        return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                    }
                },
                labels: ['Crecimiento', 'Ingresos', 'Utilidad/Pérdida'],
                xaxis: {
                    type: 'string',
                    lines: {
                        show: true,
                    },
                    categories: [],
                    labels: {
                        style: {
                            cssClass: "grey--text text--lighten-2 fill-color",
                        }
                    },
                },
                yaxis: [
                    {
                        seriesName: "Crecimiento",
                        min: 0,
                        show: true,
                        type: "numeric",
                        lines: {
                            show: true,
                        },
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#dedee0'
                        },
                        labels: {
                            style: {
                                colors: '#4989f6',
                            },
                            formatter: function (value) {
                                return '$ '+formatToUnits(value, 2);
                                // return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                            }
                        },
                        title: {
                            text: 'Crecimiento',
                            style: {
                                color: '#4989f6',
                            }
                        },
                        tooltip: {
                            enabled: false
                        },
                    },{
                        seriesName: "Ingresos",
                        min: 0,
                        show: true,
                        type: "numeric",
                        opposite: true,
                        lines: {
                            show: true,
                        },
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#05754e'
                        },
                        labels: {
                            show: true,
                            style: {
                                colors: '#05754e',
                            },
                            formatter: function (value) {
                                return '$ '+formatToUnits(value, 2);
                                // return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                            }
                        },
                        title: {
                            text: 'Ingreso / Utilidad o Pérdida',
                            style: {
                                color: '#05754e',
                            }
                        },
                    },{
                        seriesName: "Ingresos",
                        min: 0,
                        type: "numeric",
                        show: false,
                        opposite: true,
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#00E396'
                        },
                        labels: {
                            show: true,
                            style: {
                                colors: '#05754e',
                            },
                            formatter: function (value) {
                                return '$ '+formatToUnits(value, 2);
                                // return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                            }
                        },
                        title: {
                            text: 'Utilidad/Pérdida',
                            style: {
                                color: '#05754e',
                            }
                        },
                    }
                ]
            }
        }),
        filters: {
            toCurrency (value) {
                if (typeof value !== "number") {
                    return value;
                }
                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    useGrouping: true,
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            },
            toTwoDecimal (value) {
                if (typeof value !== "number") {
                    return value;
                }
                let formatter = new Intl.NumberFormat('en-US', {
                    useGrouping: false,
                    maximumSignificantDigits: 2,
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            }
        },
        computed: {
            computedStartDateFormatted() {
                return this.editedItem.start_date ? this.formatDate(this.editedItem.start_date) : '';
            },
            computedFinishDateFormatted() {
                return this.editedItem.finish_date ? this.formatDate(this.editedItem.finish_date) : '';
            },
            containerCSS() {
                return {
                    width: "100%",
                    height: this.height,
                };
            }
        },
        methods: {
            formatDate(value) {
                if (!value) return null;
                let theDate = value.toString().substr(0, 10);
                const [year, month, day] = theDate.split('-')
                return `${day}/${month}/${year}`;
            },
            parseDate(date) {
                if (!date) return null;
                let theDate = date.toString().substr(0, 10);
                const [month, day, year] = theDate.split('/');
                return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
            },
            getEvents() {
                this.loadingData = true;
                investorService.getListInvestorEvents(this.selectedInvestorId).then(records => {
                    // console.log('eventos: ', records.data.events);
                    let project_id = this.$route.params.id;
                    let dataRecord = null;
                    if (project_id === '0') {
                        dataRecord = records.data.events;
                    } else {
                        let eventFilteredList = records.data.events.filter(v => v.event_project_id === project_id);
                        dataRecord = eventFilteredList;
                        // console.log('eventFilteredList: ', eventFilteredList);
                    }
                    // console.log('dataRecord: ', dataRecord);
                    this.events = dataRecord.map(e=>{
                        return {
                            ...e,
                            event_project: e.event_project??"N/A",
                        }
                    });
                   /* this.events = records.data.events.map(e=>{
                      return {
                        ...e,
                        event_project: e.event_project??"N/A",
                      }
                    });*/
                }).catch(err => {
                    console.log("Error: ", err);
                }).finally(() =>{ this.loadingData = false; });
            },
            /* Para administrar los documentos */
            loadListDocuments(item) {
                this.event_documents = Object.assign({}, item.event_docs);
                if (!this.dialogDocumentsPDF) this.dialogDocumentsPDF = true;
            },
            openPDF(document) {
                let urlPdf = document.event_document_url;
                window.open(urlPdf, "_blank");
            },
            getDataEvents(item) {
                let eventIndex = this.events.indexOf(item);
                this.event = this.events[eventIndex];
                this.chartEventOptions.title.text = 'Evento'; // this.event.event_name;
                this.seriesEvent[0].data = [];
                this.seriesEvent[1].data = [];
                this.chartEventOptions.labels = [];
                let growValues = [];
                let incomeValues = [];
                let revueneValues = [];
                let expenseValues = [];
                let labels = [];
                let growValue = {
                    x : this.event.event_name,
                    y : this.event.event_capital_grow,
                    full_label: this.event.event_name,
                    income: this.event.event_income,
                    utility: this.event.event_utility,
                };
                growValues.push(growValue);
                let incomeValue = {
                    x : 'Ingresos', // this.event.event_name,
                    y : this.event.event_income,
                    full_label: 'Ingresos'// this.event_name
                };
                incomeValues.push(incomeValue);
                let expenseValue = {
                    x : this.event.event_name,
                    y : this.event.event_expense,
                    full_label: this.event.event_name
                }
                expenseValues.push(expenseValue);
                let revenueValue = {
                    x : 'Utilidad/Pérdida', // this.event.event_name,
                    y : this.event.event_utility,
                    full_label: 'Utilidad/Pérdida' // this.event.event_name
                };
                revueneValues.push(revenueValue);
                labels.push(this.event.event_name);

                this.seriesEvent = [
                    {
                        name: 'Crecimiento',
                        type: 'column',
                        data: growValues
                    }/*,{
                        name: 'Ingresos',
                        type: 'column',
                        data: incomeValues
                    }*/,{
                        name: 'Utilidad',
                        type: 'column',
                        data: revueneValues
                    }
                ];
                this.chartEventOptions.xaxis.categories =
                    ['Crecimiento', 'Utilidad/Pérdida'];
                if (this.event !== null) {
                    // this.$refs.chartEventsInvestor.refresh();
                }
            },
            showChartEvent(item) {
                // this.getDataEvents(this.selectedInvestorId, item.id);
                this.getDataEvents(item);
                if (!this.dialogChartEvent) this.dialogChartEvent = true;
            },
            viewProject(item) {
                investorService.getProjectInvestor(item.event_project_id).then(record => {
                    this.projectData = record.value;
                    if (this.projectData !== null) this.dialogProject = true;

                });
            }
        },
        mounted() {
            this.events = [];
            let sessionInvestor = Object.assign({}, JSON.parse(sessionStorage.getItem('investor')));
            if (sessionInvestor !== null) {
                this.selectedInvestorId = sessionInvestor.user.investors[0].id;
                this.getEvents();
            }
            setTimeout(() => (this.elementVisible = true), 10);
        }
    }
</script>

<style scoped>

</style>