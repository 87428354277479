<template>
    <investor-events-component/>
</template>

<script>
import InvestorEventsComponent from "@/components/investors/InvestorEventsComponent";
export default {
    name: "InvestorEventsProjectView",
    components: {InvestorEventsComponent}
}
</script>

<style scoped>

</style>